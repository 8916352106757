<template>
    <div v-for="p in popups" :key="p._id"
        :id="p._id"
        :class="`modal show popup`">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <button
                        type="button"
                        class="close"
                        @click="
                            hideModal(p._id);
                        ">
                        ×
                    </button>
                    <!-- <div class="auth-header">
                        <div class="auth-icon"></div>
                        <p class="m-0 text-center">
                            {{ $t('Fun and fair crypto betting since 2023') }}
                        </p>
                    </div> -->
                    <div v-html="p.content">
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex';
import ui from '@/mixins/ui';
export default {
    name: 'AuthModal',
    mixins: [ui],
    data() {
        return {
            popups: []
        };
    },
    computed: {
        ...mapGetters('auth', ['authenticated'])
    },
    methods: {
        ...mapActions('popup',{
            getPopups: 'getList'
        }),
        async initPopup() {
            const res =  await this.getPopups({});
            if(res.data) {
                this.popups = res.data
            }
            console.log(this.popups)
        }
    },
    watch: {
        authenticated(auth) {
            if(auth) {
                this.initPopup();
            }
        }
    },
    mounted() {
        // var vm = this;
        // let modal = document.getElementById('popup');
        // modal.addEventListener('mousedown', function (e) {
        //     e = window.event || e;
        //     if (this === e.target) {
        //         vm.reset();
        //     }
        // });
        if(this.authenticated) {
            this.initPopup();
        }
    },
};
</script>
