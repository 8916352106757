<template>
    <div
        id="auth-modal"
        class="modal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <button
                        type="button"
                        class="close"
                        @click="
                            hideModal('auth-modal');
                            reset();
                        ">
                        ×
                    </button>
                    <div class="auth-header">
                        <div class="auth-icon"></div>
                        <!-- <p class="m-0 text-center">
                            {{ $t('Fun and fair crypto betting since 2023') }}
                        </p> -->
                    </div>
                    <ul class="tabs">
                        <li
                            id="tab-login"
                            @click="goToSlide('login')"
                            class="tab active">
                            {{ $t('login') }}
                        </li>
                        <li
                            id="tab-register"
                            class="tab"
                            @click="goToSlide('register')">
                            {{ $t('register') }}
                        </li>
                    </ul>
                    <div class="tab-slider">
                        <div
                            id="login"
                            class="form-wrapper">
                            <form
                                autocomplete="off"
                                @submit.prevent="handleLogin">
                                <div class="form-group">
                                    <label for="username">{{
                                        $t('username')
                                    }}</label>
                                    <input
                                        id="username"
                                        class="form-control"
                                        type="text"
                                        autocomplete="off"
                                        v-model="formData.username" />
                                    <span
                                        class="form-error"
                                        v-for="e in v$.formData.username
                                            .$errors"
                                        :key="e"
                                        >{{ $t(e.$message) }}</span
                                    >
                                </div>
                                <div class="form-group">
                                    <label for="password">{{
                                        $t('password')
                                    }}</label>
                                    <input
                                        id="password"
                                        class="form-control"
                                        type="password"
                                        autocomplete="new-password"
                                        v-model="formData.password" />
                                    <span
                                        class="form-error"
                                        v-for="e in v$.formData.username
                                            .$errors"
                                        :key="e"
                                        >{{ $t(e.$message) }}</span
                                    >
                                </div>

                                <div class="form-action">
                                    <button
                                        type="submit"
                                        class="btn btn-success button-action">
                                        {{ $t('login') }}
                                    </button>
                                    <!-- <a
                                        href="javascript:void(0);"
                                        class="link-action"
                                        @click="goToSlide('register')">
                                        {{ $t('Not a member?') }}
                                        <span>{{
                                            $t('Register here')
                                        }}</span></a
                                    > -->
                                </div>
                            </form>
                        </div>
                        <div
                            id="register"
                            class="form-wrapper">
                            <form
                                autocomplete="off"
                                @submit.prevent="handleRegister">
                                <div class="form-group">
                                    <label for="username">{{
                                        $t('username')
                                    }}</label>
                                    <input
                                        id="username"
                                        class="form-control"
                                        type="text"
                                        autocomplete="off"
                                        v-model="formData2.username" />
                                    <span
                                        class="form-error"
                                        v-for="e in v$.formData2.username
                                            .$errors"
                                        :key="e"
                                        >{{ $t(e.$message) }}</span
                                    >
                                </div>
                                <div class="form-group">
                                    <label for="name">{{ $t('nickname') }}</label>
                                    <input
                                        id="name"
                                        class="form-control"
                                        type="text"
                                        autocomplete="off"
                                        v-model="formData2.name" />
                                    <span
                                        class="form-error"
                                        v-for="e in v$.formData2.name.$errors"
                                        :key="e"
                                        >{{ $t(e.$message) }}</span
                                    >
                                </div>
                                <div style="display: grid; grid-template-columns: repeat(2, 1fr);gap: .5rem;">
                                     <div class="form-group">
                                        <label for="password">{{
                                            $t('password')
                                        }}</label>
                                        <input
                                            id="password"
                                            class="form-control"
                                            type="password"
                                            autocomplete="new-password"
                                            v-model="formData2.password" />
                                        <span
                                            class="form-error"
                                            v-for="e in v$.formData2.username
                                                .$errors"
                                            :key="e"
                                            >{{ $t(e.$message) }}</span
                                        >
                                    </div>
                                    <div class="form-group">
                                        <label for="confirm-password">{{
                                            $t('confirm password')
                                        }}</label>
                                        <input
                                            id="confirm-password"
                                            class="form-control"
                                            type="password"
                                            autocomplete="new-password"
                                            v-model="formData2.confirm_password" />
                                        <span
                                            class="form-error"
                                            v-for="e in v$.formData2
                                                .confirm_password.$errors"
                                            :key="e"
                                            >{{ $t(e.$message) }}</span
                                        >
                                    </div>
                                </div>
                               
                                <div style="display: grid; grid-template-columns: repeat(3, 1fr);gap: .5rem;">
                                     <div class="form-group">
                                        <label for="bank-name">{{
                                            $t('bank name')
                                        }}</label>
                                        <input
                                            id="bank-name"
                                            class="form-control"
                                            type="text"
                                            v-model="formData2.bank_name" />
                                        <span
                                            class="form-error"
                                            v-for="e in v$.formData2
                                                .bank_name.$errors"
                                            :key="e"
                                            >{{ $t(e.$message) }}</span
                                        >
                                    </div>
                                    <div class="form-group">
                                        <label for="account-holder">{{
                                            $t('account holder')
                                        }}</label>
                                        <input
                                            id="account-holder"
                                            class="form-control"
                                            type="text"
                                            v-model="formData2.account_holder" />
                                        <span
                                            class="form-error"
                                            v-for="e in v$.formData2
                                                .account_holder.$errors"
                                            :key="e"
                                            >{{ $t(e.$message) }}</span
                                        >
                                    </div>
                                    <div class="form-group">
                                        <label for="account-number">{{
                                            $t('account number')
                                        }}</label>
                                        <input
                                            id="account-number"
                                            class="form-control"
                                            type="text"
                                            v-model="formData2.account_number" />
                                        <span
                                            class="form-error"
                                            v-for="e in v$.formData2
                                                .account_number.$errors"
                                            :key="e"
                                            >{{ $t(e.$message) }}</span
                                        >
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="mobile_number">{{
                                        $t('phone number')
                                    }}</label>
                                    <input
                                        id="mobile_number"
                                        class="form-control"
                                        type="text"
                                        autocomplete="off"
                                        v-model="formData2.mobile_number" />
                                </div>
                                <div class="form-group">
                                    <label for="pin">{{
                                        $t('charging/exchange password')
                                    }}</label>
                                    <input
                                        id="pin"
                                        class="form-control"
                                        type="password"
                                        autocomplete="off"
                                        @keypress="validateNumber($event)"
                                        v-model="formData2.pin" maxlength="4"/>
                                    <span
                                        class="form-error"
                                        v-for="e in v$.formData2
                                            .pin.$errors"
                                        :key="e"
                                        >{{ $t(e.$message) }}</span
                                    >
                                </div>
                                <div class="form-group">
                                    <label for="referral-code">{{
                                        $t('referral code')
                                    }}</label>
                                    <input
                                        id="referral-code"
                                        class="form-control"
                                        type="text"
                                        autocomplete="off"
                                        v-model="formData2.referral_code" />
                                </div>
                                <div class="form-action">
                                    <button
                                        type="submit"
                                        class="btn btn-primary button-action">
                                        {{ $t('register') }}
                                    </button>
                                    <!-- <a
                                        href="javascript:void(0);"
                                        @click="goToSlide('login')"
                                        class="link-action">
                                        {{ $t('Already a member?') }}
                                        <span>{{ $t('Login here') }}</span></a
                                    > -->
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    required,
    minLength,
    maxLength,
    sameAs,
    helpers,
} from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import ui from '@/mixins/ui';
export default {
    name: 'AuthModal',
    mixins: [ui],
    data() {
        return {
            waiting: false,
            formData: {
                username: null,
                name: null,
            },
            formData2: {
                username: null,
                name: null,
                password: null,
                confirm_password: null,
                bank_name: null,
                account_holder: null,
                account_number: null,
                mobile_number: null,
                pin: null,
                referral_code: null,
            },
        };
    },

    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    validations() {
        return {
            formData: {
                username: {
                    required: helpers.withMessage('required', required),
                },
                password: {
                    required: helpers.withMessage('required', required),
                },
            },
            formData2: {
                username: {
                    required: helpers.withMessage('required', required),
                    minLength: helpers.withMessage(
                        'should be atleast 2 characters',
                        minLength(2)
                    ),
                },
                name: {
                    required: helpers.withMessage('required', required),
                    minLength: helpers.withMessage(
                        'should be atleast 2 characters',
                        minLength(2)
                    ),
                    maxLength: helpers.withMessage(
                        'maximum length is 32 characters',
                        maxLength(32)
                    ),
                },
                password: {
                    required: helpers.withMessage('required', required),
                    minLength: helpers.withMessage(
                        'should be atleast 4 characters',
                        minLength(4)
                    ),
                    maxLength: helpers.withMessage(
                        'maximum length is 32 characters',
                        maxLength(32)
                    ),
                },
                pin: {
                    required: helpers.withMessage('required', required),
                    minLength: helpers.withMessage(
                        'should be atleast 4 characters',
                        minLength(4)
                    ),
                    maxLength: helpers.withMessage(
                        'maximum length is 4 characters',
                        maxLength(4)
                    ),
                },
                confirm_password: {
                    sameAs: helpers.withMessage(
                        `password doesn't match`,
                        sameAs(this.formData2.password)
                    ),
                },
                bank_name: {
                    required: helpers.withMessage('required', required),
                },
                account_holder: {
                    required: helpers.withMessage('required', required),
                },
                account_number: {
                    required: helpers.withMessage('required', required),
                },
                mobile_number: {
                    required: helpers.withMessage('required', required),
                },
            },
        };
    },
    methods: {
        async handleLogin() {
            if (this.waiting) {
                return;
            }
            this.v$.formData.$touch();
            if (!this.v$.formData.$invalid) {
                var fd = new FormData();
                fd.append('username', this.formData.username);
                fd.append('password', this.formData.password);

                this.waiting = true;
                const res = await this.$store.dispatch('auth/loginUser', fd);
                this.waiting = false;
                this.$swal({
                    icon: res.data.status,
                    title: this.$t(res.data.message),
                    confirmButtonText: this.$t('ok'),
                });
                if (res.success) {
                    this.$store.dispatch('auth/getProfile');
                    this.hideModal('auth-modal');
                    this.reset();
                }
            }
        },
        async handleRegister() {
            if (this.waiting) {
                return;
            }
            this.v$.formData2.$touch();
            if (!this.v$.formData2.$invalid) {
                var fd = new FormData();
                fd.append('username', this.formData2.username);
                fd.append('name', this.formData2.name);
                fd.append('password', this.formData2.password);
                fd.append('confirm_password', this.formData2.confirm_password);
                fd.append('pin', this.formData2.pin);
                fd.append('handler_bank[physical][bank_name]', this.formData2.bank_name);
                fd.append('handler_bank[physical][account_holder]', this.formData2.account_holder);
                fd.append('handler_bank[physical][account_number]', this.formData2.account_number);
                fd.append('mobile_number', this.formData2.mobile_number);
                if (this.formData2.referral_code) {
                    fd.append('referral_code', this.formData2.referral_code);
                }

                this.waiting = true;
                const res = await this.$store.dispatch('auth/registerUser', fd);
                this.waiting = false;
                this.$swal({
                    icon: res.success ? 'success' : 'error',
                    title: this.$t(res.data.message),
                    confirmButtonText: this.$t('ok'),
                });
                if (res.success) {
                    this.hideModal('auth-modal');
                    this.reset();
                }
            }
        },
        reset() {
            this.v$.formData.$reset();
            this.formData.username = null;
            this.formData.password = null;

            this.v$.formData2.$reset();
            this.formData2.username = null;
            this.formData2.name = null;
            this.formData2.password = null;
            this.formData2.confirm_password = null;
            this.formData2.bank_name = null;
            this.formData2.account_holder = null;
            this.formData2.account_number = null;
            this.formData2.mobile_number = null;
            this.formData2.pin = null;
            this.formData2.referral_code = null;
        },
        validateNumber(evt) {
			let keyCode = (evt.keyCode ? evt.keyCode : evt.which);
			if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
				evt.preventDefault();
			}
		},
    },
    mounted() {
        var vm = this;
        let modal = document.getElementById('auth-modal');
        modal.addEventListener('mousedown', function (e) {
            e = window.event || e;
            if (this === e.target) {
                vm.reset();
            }
        });
    },
};
</script>
